import React from 'react';
import MainLayout from '../layout/MainLayout';

const Settings: React.FC = () => {
  return (
    <MainLayout>
      <h1 className="text-xl font-semibold mb-4">Paramètres</h1>
      {/* Contenu de la page des settings ici */}
    </MainLayout>
  );
};

export default Settings;
