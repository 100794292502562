import axios from 'axios';

const API_URL = `${process.env.REACT_APP_URL_API_MACADAMIA}/users/`;

export const login = async (email: string, password: string) => {
  try {
    console.log('Tentative de connexion pour:', email);
    const response = await axios.post(API_URL + 'login', {
      email,
      password,
    }, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.data.token) {
      console.log('Token reçu:', response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data));
      window.dispatchEvent(new Event('storageChange'));
    }

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data && error.response.data.message) {
        console.error('Erreur lors de la connexion:', error.response.data.message);
      } else {
        console.error('Erreur lors de la connexion:', error.message);
      }
    } else {
      console.error('Erreur inconnue lors de la connexion:', error);
    }
    throw error;
  }
};

export const getToken = () => {
  const user = localStorage.getItem('user');
  const token = user ? JSON.parse(user).token : null;
  console.log('Token récupéré:', token);
  return token;
};

export const logout = () => {
  localStorage.removeItem('user');
  window.dispatchEvent(new Event('storageChange')); // Émission d'un événement lors de la déconnexion
};
