import React, { useEffect, useState } from 'react';
import MainLayout from '../layout/MainLayout';
import axios from 'axios';
import { format } from 'date-fns';

interface Folder {
  _id: string;
  title: string;
  author: string; 
  createdAt: Date;
  settings: {
    visibility: string;
  };
}

const Folders: React.FC = () => {
  const [folders, setFolders] = useState<Folder[]>([]);

  useEffect(() => {
    const fetchFolders = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        const token = user.token;
    
        const config = {
          headers: {
            Authorization: `Bearer ${token}`
          }
        };

        const response = await axios.get(`${process.env.REACT_APP_URL_API_MACADAMIA}/dossiers`, config);
        setFolders(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des dossiers:', error);
      }
    };

    fetchFolders();
  }, []);

  return (
    <MainLayout>
      <h1 className="text-xl font-semibold mb-4">Dossiers</h1>
      <div className="overflow-x-auto shadow-md sm:rounded-lg">
        <table className="min-w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="py-3 px-6">Titre</th>
              <th scope="col" className="py-3 px-6">Auteur</th>
              <th scope="col" className="py-3 px-6">Date de création</th>
              <th scope="col" className="py-3 px-6">Visibilité</th>
            </tr>
          </thead>
          <tbody>
            {folders.map((folder) => (
              <tr key={folder._id} className="bg-white border-b hover:bg-gray-50">
                <td className="py-4 px-6">{folder.title}</td>
                <td className="py-4 px-6">{folder.author}</td>
                <td className="py-4 px-6">{format(new Date(folder.createdAt), 'dd/MM/yyyy')}</td>
                <td className="py-4 px-6">{folder.settings.visibility}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </MainLayout>
  );
};

export default Folders;
