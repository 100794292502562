import React from 'react';
import MainLayout from '../layout/MainLayout';

const Payments: React.FC = () => {
  return (
    <MainLayout>
      <h1>Paiements</h1>
      {/* Contenu de la page des paiements ici */}
    </MainLayout>
  );
};

export default Payments;
