import axios from 'axios';

interface LogData {
  userId?: string; 
  timestamp?: Date;
  platform?: string;  
  appVersion?: string;
  deviceInfo?: object;
  type: string;
  category: string;
  subCategory?: string; 
  details: object;
  outcome: string;
  url?: string;
  ipAddress?: string;
  userAgent?: string;
  sessionID?: string;
  performance?: object;
}

const API_URL = process.env.REACT_APP_URL_API_MACADAMIA;

const createLog = async (logData: LogData) => {
  const token = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!).token : null;
  console.log('Token pour les logs:', token);

  try {
    await axios.post(`${API_URL}/logs`, logData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
  } catch (error) {
    console.error('Erreur lors de l\'envoi du log', error);
  }
};

export default {
  createLog,
};
