import React, { ChangeEvent, FormEvent } from 'react';

interface StaffLoginFormProps {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void; 
  formData: {
    email: string;
    password: string;
  };
}

const StaffLoginForm: React.FC<StaffLoginFormProps> = ({ onSubmit, onInputChange, formData }) => {
  return (
    <div className="flex justify-center items-center h-screen">
      <form className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md" onSubmit={onSubmit}>
        <h1 className="text-lg font-semibold text-gray-900">Connexion</h1>
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
          <input
            className="mt-1 w-full px-3 py-2 border rounded-md shadow-sm text-gray-700 focus:outline-none focus:ring-green-300 focus:border-green-300"
            name="email"
            type="email"
            placeholder="Email"
            value={formData.email}
            onChange={onInputChange}
          />
        </div>
        <div>
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">Mot de passe</label>
          <input
            className="mt-1 w-full px-3 py-2 border rounded-md shadow-sm text-gray-700 focus:outline-none focus:ring-green-300 focus:border-green-300"
            name="password"
            type="password"
            placeholder="******************"
            value={formData.password}
            onChange={onInputChange}
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            className="px-6 py-2 text-white bg-green-400 hover:bg-green-500 focus:outline-none focus:ring-2 focus:green-400 focus:ring-offset-2 rounded-md"
            type="submit"
          >
            Se connecter
          </button>
        </div>
      </form>
    </div>
  );
};

export default StaffLoginForm;
