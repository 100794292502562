import React, { useEffect, useState } from 'react';
import MainLayout from '../layout/MainLayout';
import axios from 'axios';
import { format } from 'date-fns';
import LicenceFeaturesModal from '../widgets/LicenceFeaturesModal';

interface FeatureDetail {
  name: string;
  price: number;
  currency: string;
  discountedPrice?: number;
}

interface Option {
  type: string;
  details: FeatureDetail[];
}

interface Country {
  name: string;
  options: Option[];
}

interface Features {
  countries: Country[];
}

interface Licence {
  _id: string;
  licenseKey: string;
  userId: string; 
  issueDate: Date;
  expiryDate: Date;
  status: string;
  features: Features;
}

const Licences: React.FC = () => {
  const [licences, setLicences] = useState<Licence[]>([]);
  const [selectedLicenceFeatures, setSelectedLicenceFeatures] = useState<Features | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchLicences = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        const token = user.token;
        
        const config = {
          headers: {
            Authorization: `Bearer ${token}`
          }
        };
        
        const response = await axios.get(`${process.env.REACT_APP_URL_API_MACADAMIA}/licences`, config);
        console.log(response.data); 
        setLicences(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des licences:', error);
      }
    };
  
    fetchLicences();
  }, []);  

  return (
    <MainLayout>
      <h1 className="text-xl font-semibold mb-4">Licences</h1>
      <div className="overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="py-3 px-6">ID</th>
              <th scope="col" className="py-3 px-6">Clé</th>
              <th scope="col" className="py-3 px-6">Features</th>
              <th scope="col" className="py-3 px-6">Client</th>
              <th scope="col" className="py-3 px-6">Date d'achat</th>
              <th scope="col" className="py-3 px-6">Date d'expiration</th>
              <th scope="col" className="py-3 px-6">Statut</th>
            </tr>
          </thead>
          <tbody>
            {licences.map((licence) => (
              <tr key={licence._id} className="bg-white border-b hover:bg-gray-50">
                <td className="py-4 px-6">{licence._id}</td>
                <td className="py-4 px-6">{licence.licenseKey}</td>
                <td className="py-4 px-6">
                  <button
                    className="text-blue-500 hover:text-blue-700"
                    onClick={() => {
                      setSelectedLicenceFeatures(licence.features);
                      setIsModalOpen(true);
                    }}
                  >
                    Voir Features
                  </button>
                </td>
                <td className="py-4 px-6">{licence.userId}</td>
                <td className="py-4 px-6">{format(new Date(licence.issueDate), 'dd/MM/yyyy')}</td>
                <td className="py-4 px-6">{format(new Date(licence.expiryDate), 'dd/MM/yyyy')}</td>
                <td className="py-4 px-6">{licence.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && selectedLicenceFeatures && (
        <LicenceFeaturesModal
          features={selectedLicenceFeatures}
          onClose={() => setIsModalOpen(false)}
        />
      )}

    </MainLayout>
  );
};

export default Licences;
