import React, { useEffect, useState } from 'react';
import MainLayout from '../layout/MainLayout';
import Pagination from '../widgets/Pagination';
import axios from 'axios';
import { format } from 'date-fns';

interface User {
  _id: string;
  email: string;
  civilite: string;
  firstName: string;
  lastName: string;
  pays: string;
  role: string;
  createdAt: Date;
}

const Users: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const limit = 20;
  const totalPages = Math.ceil(totalUsers / limit);

  // Fonction pour changer de page
  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };  

  useEffect(() => {
    const fetchUsers = async () => {
        try {
          const user = JSON.parse(localStorage.getItem('user') || '{}');
          const token = user.token;
      
          const config = {
            headers: {
              Authorization: `Bearer ${token}`
            }
          };
      
          const response = await axios.get(`${process.env.REACT_APP_URL_API_MACADAMIA}/users?page=${page}&limit=${limit}&sort=createdAt&order=desc`, config);
          console.log(response.data); 
          setUsers(response.data.users || response.data); 
          setTotalUsers(response.data.total);
        } catch (error) {
          console.error('Erreur lors de la récupération des utilisateurs:', error);
        }
    };

    fetchUsers();
  }, [page, limit]);

  const filteredUsers = users.filter(user => {
    const searchWords = searchTerm.toLowerCase().split(' ').filter(word => word.trim() !== '');
  
    return searchWords.every(word => 
      user._id.toLowerCase().includes(word) ||
      user.email.toLowerCase().includes(word) ||
      user.civilite.toLowerCase().includes(word) ||
      user.firstName.toLowerCase().includes(word) ||
      user.lastName.toLowerCase().includes(word) ||
      user.pays.toLowerCase().includes(word) ||
      user.role.toLowerCase().includes(word) ||
      format(new Date(user.createdAt), 'dd/MM/yyyy').toLowerCase().includes(word)
    );
  });
  
  return (
    <MainLayout>
      <h1 className="text-xl font-semibold mb-4">Utilisateurs ({totalUsers})</h1>
      <input
        type="text"
        className="mb-4 p-2 border border-gray-300 rounded-lg focus:border-green-300"
        placeholder="Recherche..."
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500">

          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
                <th scope="col" className="py-3 px-6">Email</th>
                <th scope="col" className="py-3 px-6">Civilité</th>
                <th scope="col" className="py-3 px-6">Prénom</th>
                <th scope="col" className="py-3 px-6">Nom</th>
                <th scope="col" className="py-3 px-6">Pays</th>
                <th scope="col" className="py-3 px-6">Inscription</th>
                <th scope="col" className="py-3 px-6">Rôle</th>
            </tr>
          </thead>

        <tbody>
            {filteredUsers.map((user) => (
            <tr key={user._id} className="bg-white border-b hover:bg-gray-50">
                <td className="py-4 px-6">{user.email}</td>
                <td className="py-4 px-6">{user.civilite}</td>
                <td className="py-4 px-6">{user.firstName}</td>
                <td className="py-4 px-6">{user.lastName}</td>
                <td className="py-4 px-6">{user.pays}</td>
                <td className="py-4 px-6">{format(new Date(user.createdAt), 'dd/MM/yyyy')}</td>
                <td className="py-4 px-6">{user.role}</td>
            </tr>
            ))}
        </tbody>

        </table>
      </div>

      <Pagination page={page} limit={limit} totalUsers={totalUsers} onPageChange={handlePageChange} />

    </MainLayout>
  );
};

export default Users;
