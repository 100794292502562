import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login as authServiceLogin, getToken } from '../../services/authService';
import logService from '../../services/logService';
import StaffLoginForm from '../form/login/StaffLoginForm';
import axios from 'axios';

const API_URL = process.env.REACT_APP_URL_API_MACADAMIA;

const Login: React.FC = () => {
  const [loginForm, setLoginForm] = useState({ email: '', password: '' });
  const [signupForm, setSignupForm] = useState({ email: '', password: '' });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  const fetchIpAddress = async (): Promise<string> => {
    try {
      const response = await axios.get(`${API_URL}/ip/my-ip`, {
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      });
      return response.data.ip;
    } catch (error) {
      console.error('Erreur lors de la récupération de l\'adresse IP:', error);
      return 'Indisponible';
    }
  };

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const ipAddress = await fetchIpAddress();
    try {
      await authServiceLogin(loginForm.email, loginForm.password);
      logService.createLog({
        type: 'Staff Login',
        category: 'Auth',
        platform: 'Frontend',
        subCategory: 'StandardLogin',
        details: { message: 'Connexion réussie' },
        outcome: 'Success',
        appVersion: "1.0.0",
        deviceInfo: {
          os: navigator.platform,
          browserName: navigator.appName,
          browserVersion: navigator.appVersion,
        },
        url: window.location.href,
        ipAddress,
        userAgent: navigator.userAgent,
        sessionID: "session_id_fictif",
        performance: {
          loadTime: window.performance.timing.domContentLoadedEventEnd - window.performance.timing.navigationStart,
        },
      });
      navigate('/dashboard');
    } catch (error) {
      logService.createLog({
        type: 'Staff Login',
        category: 'Auth',
        platform: 'Frontend',
        subCategory: 'StandardLogin',
        details: { message: 'Connexion échouée', error: JSON.stringify(error) },
        outcome: 'Failure',
        appVersion: "1.0.0",
        deviceInfo: {
          os: navigator.platform,
          browserName: navigator.appName,
          browserVersion: navigator.appVersion,
        },
        url: window.location.href,
        ipAddress,
        userAgent: navigator.userAgent,
        sessionID: "session_id_fictif",
        performance: {
          loadTime: window.performance.timing.domContentLoadedEventEnd - window.performance.timing.navigationStart,
        },
      });
      let message = "Une erreur est survenue";
      setErrorMessage(message);
      setTimeout(() => setErrorMessage(null), 5000);
    }
  };

  const handleSignup = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_URL}/users`, signupForm, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('Utilisateur créé:', response.data);
      setSignupForm({ email: '', password: '' });
      alert('Inscription réussie, vous pouvez maintenant vous connecter.');
    } catch (error) {
      console.error('Erreur lors de l\'inscription:', error);
      setErrorMessage("Une erreur est survenue lors de l'inscription");
      setTimeout(() => setErrorMessage(null), 5000);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLoginForm(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSignupInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSignupForm(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="flex justify-center items-center h-screen overflow-hidden font-ttnorms">
      {errorMessage && (
        <div className="absolute top-5 right-5 bg-red-500 text-white py-2 px-4 rounded transition-opacity duration-500 ease-in-out">
          {errorMessage}
        </div>
      )}
      <div className="w-full max-w-xs overflow-auto py-4">
        <StaffLoginForm
          onSubmit={handleLogin}
          onInputChange={handleInputChange}
          formData={loginForm}
        />
        {/* 
        <StaffRegisterForm
          onSubmit={handleSignup}
          onInputChange={handleSignupInputChange}
          formData={signupForm}
        />
        */}
      </div>
    </div>
  );
};

export default Login;
