import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';


interface PaginationProps {
  page: number;
  limit: number;
  totalUsers: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ page, limit, totalUsers, onPageChange }) => {
  const totalPages = Math.ceil(totalUsers / limit);
  const start = (page - 1) * limit + 1;
  const end = Math.min(start + limit - 1, totalUsers);

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Afficher
            <span className="font-medium"> {start} </span>
            à
            <span className="font-medium"> {end} </span>
            de
            <span className="font-medium"> {totalUsers} </span>
            résultats
          </p>
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button
              onClick={() => onPageChange(Math.max(1, page - 1))}
              disabled={page === 1}
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              aria-label="Previous"
            >
              <ChevronLeftIcon className="h-4 w-4" aria-hidden="true" /> 

            </button>

            {Array.from({ length: totalPages }, (_, i) => i + 1).map((num) => (
              <button
                key={num}
                onClick={() => onPageChange(num)}
                aria-current={page === num ? "page" : undefined}
                className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${page === num ? "z-10 bg-green-400 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0"}`}
              >
                {num}
              </button>
            ))}
            <button
              onClick={() => onPageChange(Math.min(totalPages, page + 1))}
              disabled={page === totalPages}
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              aria-label="Next"
            >
              <ChevronRightIcon className="h-4 w-4" aria-hidden="true" /> 

            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
