import React, { ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../contexts/authContext';

interface PublicRouteProps {
  children?: ReactNode;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return <div>Chargement...</div>;
  }

  return user ? <Navigate to="/dashboard" replace /> : (children ? <>{children}</> : <Outlet />);
};

export default PublicRoute;
