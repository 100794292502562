import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/authContext';
import Login from './components/page/Login';
import Dashboard from './components/page/Dashboard';
import Users from './components/page/Users';
import Folders from './components/page/Folders';
import Licences from './components/page/Licences';
import Payments from './components/page/Payments';
import Logs from './components/page/Logs';
import Settings from './components/page/Settings';
import PrivateRoute from './components/page/PrivateRoute';
import PublicRoute from './components/page/PublicRoute';

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleStorageChange = () => {
      const user = localStorage.getItem('user');
      if (!user) {
        navigate('/login');
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [navigate]);

  return (
    <AuthProvider>
      <Routes>
        <Route path="/login" element={<PublicRoute />}>
          <Route index element={<Login />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="users" element={<Users />} />
          <Route path="folders" element={<Folders />} />
          <Route path="licenses" element={<Licences />} />
          <Route path="payments" element={<Payments />} />
          <Route path="logs" element={<Logs />} />
          <Route path="settings" element={<Settings />} />
        </Route>
      </Routes>
    </AuthProvider>
  );
}

export default App;
