import React from 'react';

interface FeatureDetail {
  name: string;
  price: number;
  currency: string;
  discountedPrice?: number;
}

interface Option {
  type: string;
  details: FeatureDetail[];
}

interface Country {
  name: string;
  options: Option[];
}

interface Features {
  countries: Country[];
}

interface LicenceFeaturesModalProps {
  features: Features | null;
  onClose: () => void;
}

const LicenceFeaturesModal: React.FC<LicenceFeaturesModalProps> = ({ features, onClose }) => {
  if (!features) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-end">
      <div className="w-full max-w-md p-5 overflow-y-auto bg-white shadow-lg">
        <div className="text-left">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Détails des Features</h3>
          <div className="mt-2 px-7 py-3 text-left">
            {features.countries.map((country, index) => (
              <div key={index} className="mb-4">
                <h4 className="text-md font-semibold">{country.name}</h4>
                {country.options.map((option, optionIndex) => (
                  <div key={optionIndex} className="ml-4">
                    <p className="text-sm font-semibold">{option.type}</p>
                    {option.details.map((detail, detailIndex) => (
                      <p key={detailIndex} className="text-sm">
                        {detail.name}: {detail.price} {detail.currency}{detail.discountedPrice ? ` (Solde: ${detail.discountedPrice})` : ""}
                      </p>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="items-center py-3">
            <button
              className="px-4 py-2 bg-gray-800 text-white text-base font-medium rounded shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500"
              onClick={onClose}
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicenceFeaturesModal;
